<template>
  <div
    class="h-dynamic-screen flex w-full flex-col bg-white"
    data-el="ui-nav-mobile"
  >
    <div
      class="bg-blue text-white"
      :class="{ 'pb-4': $slots.search }"
    >
      <div
        class="flex w-full items-center justify-between px-8 pb-5 pt-10"
      >
        <NuxtLink
          :to="props.baseUrl"
          @click="emit('close')"
        >
          <UiLogo
            class="h-5"
            variant="white"
          />
        </NuxtLink>

        <UiIcon
          name="close"
          :height="16"
          class="cursor-pointer text-white"
          @click="emit('close')"
        />
      </div>

      <slot name="extra-header" />

      <div
        v-if="$slots.search"
        class="px-8 pt-4"
      >
        <slot name="search" />
      </div>

      <div
        v-if="breadcrumbs && breadcrumbs.length"
        class="flex flex-col items-start px-8 py-2.5 text-sm font-bold"
      >
        <button
          v-for="breadcrumb in breadcrumbs"
          :key="breadcrumb.link"
          class="flex items-center gap-2.5 py-2.5 [&:last-child]:pb-0"
          @click="$emit('click:breadcrumb', breadcrumb)"
        >
          <UiIcon
            name="arrow-left"
            :width="14"
            :height="14"
          />
          {{ breadcrumb.title }}
        </button>
      </div>
    </div>

    <slot />
  </div>
</template>

<script lang="ts" setup>
import UiLogo from '../../UiLogo/UiLogo.vue'
import UiIcon from '../../UiIcon/UiIcon.vue'

interface Breadcrumb {
  title: string
  link: string
}

interface UiNavMobile {
  baseUrl?: string
  breadcrumbs?: Breadcrumb[] | []
}

const props = withDefaults(defineProps<UiNavMobile>(), {
  baseUrl: '',
  breadcrumbs: () => [],
})

const emit = defineEmits<{
  /** notify about close */
  (e: 'close'): void
  (e: 'click:breadcrumb', breadcrumb: Breadcrumb): void
}>()
</script>
